body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.displayflex {
  display: flex;
}

.jc-center {
  justify-content: center;
}

.jc-space-evenly {
  justify-content: space-evenly;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-space-around {
  justify-content: space-around;
}

.alignitem {
  align-items: center;

}


.flexcolumn {
  flex-direction: column;
}


.h100 {
  height: 100%;
}

.h80 {
  height: 80%;
}

.h10 {
  height: 10%;
}

.h80 {
  height: 80%;
}

.w100 {
  width: 100%;
}

.w70 {
  width: 70%;
}

.w25 {
  width: 25%;
}

.w80 {
  width: 80%;
}
