* {
  padding: 0;
  margin: 0;
}

body {
background: rgb(21,22,27);
/* background-color: black; */
}

#header {
  font-size: 1.2em;
  z-index: 1;
  position: fixed;
  width: 100vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

}



header h1 {
  color: #c770f0;
  margin-left: 2vw;

}


header nav {
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2vw;
}


header nav a {

  box-sizing: border-box;
  color: white;
  text-decoration: none;
  font-weight: 700;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  border-radius: 50px;
  padding: 0 15px;
  transition: 0.2s;
}

header nav a:hover {
  /* background: linear-gradient(61deg,#7b7bff, #ff395c); */
  background  :linear-gradient(45deg, red, blue);
  transform:scale(1.1);

  /* color: transparent; */
}


/* 
header nav a::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: #c770f0;
  transition: width .3s;

}

header nav a:hover::after {
  width: 100%;
} */


.Home {
  min-height: 100vh;
}

.Home #Intro {

  z-index: 0;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home #Intro .svg-zone {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.svg-zone img {
  width: 60%;
}



.fixed-text-zone {
    font-size: 1.4em;
}


#Intro .text-zone {
  color: white;
  height: 50%;
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  align-items:baseline;
  flex-direction: column;
}



.typewriter-div{
  font-size: 1.6em;
  /* color:#FEA1BF; */
  
  
}

#homebg {
  height: 100vh;
  width: 100%;
  z-index: -2;
  opacity: 0.1;
  position: absolute;
}

#personalIntro {
  box-sizing: border-box;
  /* border: 1px solid white; */
  color: white;

}

#personalIntro div{
  box-sizing: border-box;
  /* border: 1px solid white; */
}


.socialmedia a {
  height: 60%;
}

.socialmedia img {
  height: 100%;
}

#myphoto {
  height: 60%;
  object-fit:fill;
  border-radius: 50%;
  border: 2px solid white;
}


.tiltprofile:hover {

  /* background: rgba(31, 31, 31, 0.5); */
  background: linear-gradient(309deg, #3a3c3c 0%, #180133 100%);

  /* background:  #fff; */
}

.tiltprofile:hover .purplelove {
  transition: 1s;
  background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}


.rainbow {
  background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf, #eedd44);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}


footer {
  background-color: #49135a39;
  width: 100vw;
  display: flex;
  justify-content: center;
  height: 10vh;
  font-weight: 300;
  align-items: center;
}

footer h2 {
  font-weight: 300;
  
}


section span:hover {
  font-weight: 700;
}



#resume {
  position: absolute;
  top: 20vh;
  width: 100vw;
}


#resume section {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


#resume section div {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* color: #cf23cf; */
  font-size: 1.6em;
}

#resume section div img {
  border: 5px dotted rgb(255, 255, 255);
}


#resume section div h1 {
  margin-bottom: 50px;
  border-bottom: 5px dotted white;
}


#resume section div a {
  text-decoration: none;
  color: white;
  border: 1px solid white;
  background-color: #180133;
  padding: 5px 20px;
  border-radius: 50px;
  font-size: 0.8em;
  transition: 0.5s;

}

#resume section div a:hover {
  font-size: 1em;
  box-shadow: 0px 0px 60px -10px white;
}